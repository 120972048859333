
let key = {};
var EnvName = 'production';
let IPFS_IMG = "https://nft-venera.infura-ipfs.io/ipfs"
var networkVersion=''
var BNBProvider="";
let Back_Url=""
let decimalValues = 1000000000000000000;
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}
if (EnvName === "testlive") {
    var exchangeAddress = "";
    var adminaddress = "";
    var trade = ("").toLowerCase();
    var API_URL = "";
    var IMAGE_URL = '';
    var PORT = 2053;
    Back_Url=''
    var Front_URL=""
    BNBProvider             =   "";
    var singleContract    =   "".toLowerCase();
    var multipleContract   =   "".toLowerCase();
    networkVersion      =   '';    
}
else if (EnvName === "production") {
    var exchangeAddress = "";
    var adminaddress = "";
  
    var trade = ("").toLowerCase();
    var API_URL = "https://api.veneranft.com";
    var IMAGE_URL = 'https://api.veneranft.com/images/user';
    var PORT = 2053;
    Back_Url='https://api.veneranft.com'
    var Front_URL="https://veneranft.com"
    BNBProvider             =   "https://bsc-dataseed1.binance.org/";
    var singleContract    =   "0x9b18f0dbd5589e8e44e1a6b21a1a2671492339d2".toLowerCase();
    var multipleContract   =   "0x078d8092d57e49f11bf0110b9861b19aa8e8ba56".toLowerCase();
    networkVersion      =   56;
}
else if(EnvName === "local") {
    var exchangeAddress = "";
    var adminaddress = "";
    var trade = ("").toLowerCase();
    var API_URL = 'http://localhost:2002';
    // var API_URL = "https://admin.xdcnft.com";
    var IMAGE_URL = 'http://localhost:2002/images/user';
    var PORT = 2002;
    Back_Url='http://localhost:2002'
    // Back_Url='https://admin.xdcnft.com'
    var Front_URL="http://localhost:3001/XDCNFT"
    BNBProvider             =   "https://api.avax-test.network/ext/bc/C/rpc";
    var singleContract    =   "0x201562fe1CF3BD7C4373A7BaD0a0dBE1c1A697b5".toLowerCase();
    var multipleContract   =   "0x31a1A7ddcF60d67B0b32ceC94b0f566cA1Ff8565".toLowerCase();
    networkVersion      =   43113;
}

key = {
    secretOrKey: "",
    Recaptchakey: "",
    // API:`${API_URL}:${PORT}/v1`,
    API:`${API_URL}/v1`,
    IMAGE_URL:IMAGE_URL,
    exchangeAddress:exchangeAddress,
    toasterOption:toasterOption,
    IPFS_IMG:IPFS_IMG,
    networkVersion:networkVersion,
    adminaddress:adminaddress,
    decimalValues:decimalValues,
    Back_Url:Back_Url,
    singleContract:singleContract,
    multipleContract:multipleContract,
    networkVersion:networkVersion,
    BNBProvider:BNBProvider,
    Front_URL:Front_URL,
    trade:trade
};

export default key;